import { createApp } from 'vue'
import { createWebHistory, createRouter } from 'vue-router'
import moment from 'moment/min/moment-with-locales.min.js'
moment.locale('fr')

import mitt from 'mitt';
const emitter = mitt();
import "./styles/ui.scss"
import "./chat.scss"
import { createI18n } from "vue-i18n"
import SearchHomeSection from "./components/SearchHomeSection.vue";
import MessagesFr from "../src/LightyShare/LightyShareBundle/Resources/translations/messages.fr.yml"
import dropdownDirective from "./directives/dropdownDirective";

const messages = {
    fr: MessagesFr,
    ja: {
        message: {
            hello: 'こんにちは、世界'
        }
    }
}

const i18n = createI18n({
    locale: 'fr', // set locale
    fallbackLocale: 'fr', // set fallback locale
    messages, // set locale messages
    // If you need to specify other options, you can set other options
    // ...
})

const app = createApp(SearchHomeSection)
app.config.globalProperties.emitter = emitter
console.log(window.$data)
if (window.$data) {
    app.config.globalProperties.$symfony = window.$data;
}
app.config.globalProperties.path = function (route, params = {}) {
    const name = route
    if (app.config.globalProperties.$symfony && app.config.globalProperties.$symfony.router && typeof app.config.globalProperties.$symfony.router[route] === 'undefined') {
        console.error(`This route : ${route} doesn't exist`)
        return ''
    } else if (app.config.globalProperties.$symfony && app.config.globalProperties.$symfony.router && typeof app.config.globalProperties.$symfony.router[route] !== 'undefined') {
        route = app.config.globalProperties.$symfony.router[route]
    } else {
        return false
    }
    let path = route['path'];
    const args = route['path'].match(/{[a-z]{1,}}/gm)
    if (args && args.length > 0) {
        args.forEach((arg) => {
            const key = arg.replace('{', '').replace('}', '')
            if (typeof params[key] !== 'undefined') {
                path = path.replace(arg, params[key])
            } else if (typeof route['defaults'][key] !== 'undefined') {
                path = path.replace(arg, route['defaults'][key])
            } else {
                console.error(`No param named ${key} set for ${name} route`)
            }
        })
    }
    return path
}


app.config.globalProperties.asset = function (path) {
    return path
}
console.log(window.Beacon)
app.config.globalProperties.Beacon = window.Beacon

app.use(i18n)
app.directive('dropdown', dropdownDirective)
// app.directive('dropdown', dropdownDirective)
app.config.globalProperties.$moment=moment
// app.config.globalProperties.$lightyshareConfig=window.$lightyshareConfig
app.mount('#searchHomeSection')