<template>
  <div class="tab-pane fade show active" id="pills-location" role="tabpanel" aria-labelledby="pills-home-tab">
    <form :action="path('catalogue-search')"  id="form-adress" method="get">
      <input-start-search />
      <input-city/>
      <div class="d-flex justify-content-between">
        <small>
          {{ $t('home.create-ad-start')}} <a :href="path('lighty_share_lighty_share_inscription')">{{ $t('home.create-ad-link') }}</a> {{$t('home.create-ad-end')}}
<!--          {{ 'home.create-ad' | trans({'%link_start%' : '<a :href="path('lighty_share_lighty_share_inscription')">', '%link_end%' : '</a>'}) | raw }}-->
        </small>

        <button type="submit" id="form-boutton" class="btn btn-primary">
          {{$t('home.search')}}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
  import inputCity from './inputCity.vue'
  import inputStartSearch from './inputStartSearch.vue'
  export default {
    components: {
      inputCity,
      inputStartSearch
    },
    name: "SearchHomeSection"
  }
</script>

<style scoped>

</style>