<template>
  <div style="position:relative">
    <input v-model="citySearch" class="form-control" :placeholder="$t('home.input.2')" type="text" @focusin="suggestionsFocus = true" v-click-outside="looseFocus">
    <ul class="custom_autocomplete" v-show="showSuggestion">
      <li v-for="suggestion in suggestions" class="item clickable-item" @click.prevent="selectSuggestion(suggestion)">
        <a href="" class="clickable-item">{{suggestion.place}}</a>
      </li>
    </ul>
    <input v-model="selectedPosition" name="c" type="hidden">
  </div>
</template>

<script>
import { citySuggestions } from '../services/geocoding.js'
export default {
  name: "inputCity",
  data () {
    return {
      citySearch: '',
      selectedPosition: '',
      suggestionsFocus: false,
      suggestionsLoaded: [],
    }
  },
  watch: {
    citySearch: {
      handler (val) {
        if (val.length > 1) {
          this.searchCityName(val)
        }
      }
    }
  },
  computed: {
    showSuggestion ()
    {
      return this.suggestionsLoaded.length && this.suggestionsFocus
    },
    suggestions ()
    {
      return this.suggestionsLoaded.map((suggestion) => {
        const result = suggestion.place_name.split(', ')
        return {
          place: suggestion.place_name,
          position: suggestion.geometry.coordinates,
          city: result[0],
          region: result[1],
          country: result[2]
        }
      })
    }
  },
  methods: {
    looseFocus() {
      this.suggestionsFocus = false
    },
    onFocus (ev) {
      // console.log('on focus')
      // console.log(ev)
    },
    selectSuggestion (choice) {
      this.citySearch = choice.place
      this.selectedPosition = choice.position.join(',')
      this.suggestionsFocus = false
    },
    searchCityName (city)
    {
      citySuggestions(city)
          .then(this.saveSuggestion)
          .catch(this.handleError)
    },
    handleError (e)
    {
      console.error(e)
    },
    saveSuggestion (data)
    {
      this.suggestionsLoaded = [...data.features]
    }
  }
}
</script>

<style lang="scss">
.custom_autocomplete {
  &:before {
    display: block;
    position: absolute;
    content: "";
    width: calc(100% + 2px);
    left: -1px;
    top: -10px;
    height: 10px;
    border: 1px solid #d5d5d5;
    border-top: none !important;
    background: white;
  }
  z-index: 2;
  margin-top: -10px;
  position: absolute;
  width: 100%;
  border-radius: 0 0 10px 10px;
  box-shadow: 0 10px 17px 0px rgb(0 0 0 / 10%);
  padding: 10px;
  border: 1px solid #d5d5d5;
  background: white;
  .item {
    list-style: none;
  }
  .clickable-item {
    cursor: pointer;
    & a {
      text-underline: none !important;
      text-decoration: none;
    }
    &:hover
    {
      & a {
        color: #fc2249;
      }
      color: #fc2249;
      background: #FFEDF0;
    }
    color: #080025;
    padding: 0;
    border-radius: 3px;
    transition: all 0.2s linear;
    margin: 0 !important;
    padding: 5px 10px;
    border: none !important;
    border-radius: 8px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
}
</style>