<template>
  <div v-click-outside="looseFocus" style="position:relative">
    <input class="form-control" type="text"
           :placeholder="$t('home.input.1')" v-model="searchAsk" @focusin="onFocus" @focusout="onFocusOut">
    <div v-show="showSuggestion" class="custom_autocomplete" >
      <div v-for="slg in groups" class="suggestion-group">
        <span class="search-group-autocomplete">{{slg.name}}</span>
        <ul class="suggestion-list">
          <li v-for="s in slg.suggestions" @click.prevent="onSuggestionClick(s)" class="item clickable-item">
            <a href="" class="clickable-item" v-html="s.nom"></a>
          </li>
        </ul>
      </div>
    </div>
    <input v-if="selectedSuggestion" v-model="selectedValue" :name="selectedName" type="hidden">
  </div>
</template>

<script>
import {autocomplete} from "../services/search.js";
import {throttle} from "../utils/TimeExecution";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export default {
  name: "inputStartSearch",
  data () {
    return {
      isResultLoading: false,
      selectedSuggestion: null,
      focusedSuggestion: null,
      suggestionResponse: null,
      currentSelectedSuggestion: {},
      suggestionsLoaded: [],
      displaySuggestions: true,
      suggestionFocus: false,
      searchAsk: ''
    }
  },
  watch: {
    searchAsk: {
      handler (val) {
        this.runAutocomplete({q: val, types: 'categorie,marque,modele'})
      }
    }
  },
  methods: {
    onFocus () {
      this.suggestionFocus = true
    },
    onSuggestionClick (s) {
      this.selectedSuggestion = s
      this.searchAsk = s.term
      this.suggestionFocus = false
    },
    onFocusOut () {
      // loose focus methods after half a second delay
      setTimeout(() => {
        this.looseFocus()
      }, 500)
    },
    looseFocus() {
      this.suggestionFocus = false
    },
    handleError () {
    },
    saveSuggestion (response) {
      this.suggestionResponse = response.data
    },
    runAutocomplete (data) {
      this.autocompleteThrottle(data)
    },
    autocompleteThrottle: throttle(function (data) {
      autocomplete(data)
          .then(this.saveSuggestion)
          .catch(this.handleError)
          .finally(() => {
            this.isResultLoading = false
          })
    }, 1000, true, true),
  },
  computed: {
    selectedValue () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      return this.selectedSuggestion.id
    },
    selectedName () {
      if (this.selectedSuggestion === null) {
        return ''
      }
      const Types = {
        modele: 'mo',
        marque: 'ma',
        categorie: 'sc'
      }
      return Types[this.selectedSuggestion.type]
    },
    showSuggestion ()
    {
      return this.suggestionList.length && this.suggestionFocus
    },
    suggestionList () {
      return this.suggestionResponse === null ? [] : this.suggestionResponse.hits.hits;
    },
    suggestionListGroups () {
      return this.suggestionList.reduce((group, suggestion) => {
        if (!group.includes(suggestion._type)) {
          group.push(suggestion._type)
        }
        return group
      }, [])
    },
    groups () {
      return this.suggestionListGroups.map((v) => {
        return {
          name: capitalizeFirstLetter(v),
          suggestions: this.suggestionList.filter((suggestion) => {
            return suggestion['_type'] === v
          }).map((suggestion) => {
            let nom = suggestion['highlight'] && suggestion['highlight'].nom ? suggestion['highlight'].nom.join('') : suggestion['_source'].nom
            if (suggestion['_type'] === 'modele') {
              nom = suggestion['_source'].marque.nom + ' - ' + nom
            }
            const add = {
              type: suggestion['_type'],
              term: suggestion['_source'].nom,
              nom
            }
            return {...suggestion['_source'], ...add}
          })
        }
      })
    }
  }
}
</script>

<style scoped>

</style>